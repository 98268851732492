<template>
  <PlacesDialougePlaces v-if="isAuth" :force-selector="enableForceSelectedCity" :hide-activator="hideActivator" />
  <PlacesDialougeCities v-else :force-selector="enableForceSelectedCity" :hide-activator="hideActivator" />
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'

const { isAuthLoggedIn } = useMenuModel()
const isAuth = isAuthLoggedIn()
const props = defineProps<{
  forceSelector?: boolean,
  hideActivator?: boolean,
  withReload?: boolean
}>()

const enableForceSelectedCity = computed(() => {
  return props.forceSelector ? usePlacesStore().enableForceSelectedCity() : false
})
</script>
